const header = [
  {
    text: "Início",
    icon: "mdi-home",
    to: "/",
    permissions: ['super_admin', 'admin', 'director', 'coordinator', 'student', 'tech_secretary']
  },
  {
    text: "Agenda",
    icon: "mdi-calendar",
    to: "/courses_calendar",
    permissions: ['super_admin', 'admin', 'director']
  },
  {
    text: "Formulários",
    icon: "mdi-file-document",
    to: "/forms",
    permissions: ['super_admin', 'admin', 'director' ]
  },
  {
    text: "Equipe",
    icon: "mdi-account-group",
    to: "/members",
    permissions: ['super_admin', 'admin', 'director' ]
  },

  {
    text: "Cursos",
    icon: "mdi-school",
    to: "/courses_list",
    permissions: ['super_admin', 'admin', 'director', 'coordinator', 'student', 'tech_secretary']
  },
  {
    text: "Meus cursos",
    icon: "mdi-school",
    to: "/my_courses_list",
    permissions: ['student']
  },
  // {
  //   text: "Certificados",
  //   icon: "mdi-certificate-outline",
  //   to: "/certificate_list",
  //   permissions: ['coordinator']
  // },
  {
    text: "Formulários",
    icon: "mdi-file-document",
    to: "/my_forms",
    permissions: ['student']
  },
  {
    text: "Certificados",
    icon: "mdi-certificate-outline",
    to: "/certificate_config",
    permissions: ['super_admin', 'admin', 'director']
  },
  {
    text: "Inscriçoes",
    icon: "mdi-counter",
    to: "/enrollment_counters",
    permissions: ['super_admin', 'admin', 'director']
  },
  {
    text: "Exportações",
    icon: "mdi-file-export",
    to: "/data_exports",
    permissions: ['super_admin', 'admin', 'director']
  },
  {
    text: "Sair",
    icon: "mdi-logout",
    to: "/logout",
    permissions: ['super_admin', 'admin', 'director', 'coordinator', 'student', 'tech_secretary']
  }
];

const drawer = header;

const bottom = [
  {
    text: "Home",
    to: "/",
    icon: "$vuetify.icons.home",
    active: true
  },
  {
    text: "Atividade",
    to: "/logout",
    icon: "$vuetify.icons.logout",
    active: true
  }
];

export { header, drawer, bottom };
